<template>
  <b-card
    class="profile-header mb-2"
    :class="{'profile-header-height': !is_in_creator_view}"
    alt="cover photo"
    body-class="p-0"
  >
    <template #header>
      <div class="image-gradient" :style="background_image" v-if="!is_in_creator_view">
      </div>
    </template>
    <!-- profile picture -->
    <div class="position-relative" v-if="!is_in_creator_view">
      <div class="profile-img-container d-block d-md-flex justify-content-between col-12 col-md-auto">
        <div class="profile-img mx-auto">
          <b-link :href="linkNetwork" target="_blank" class="text-white">
            <b-img-lazy
              :src="avatar"
              rounded
              fluid
              :alt="headerData.username"
              class="b-avatar-profile-header d-block"
            />
          </b-link>
          <b-link :href="linkNetwork" target="_blank">
            <b-avatar size="29px" class="network-icon p-0" variant="light">
              <b-img :src="getIconWhite(headerData.main.network)" :class="getIconColor(headerData.main.network)" fluid/>
            </b-avatar>
          </b-link>
        </div>
        <!-- profile title -->
        <div :class="`profile-title ml-md-3 ${(add_class) ? 'mt-50 d-none' : 'd-block'}`">
          <h2 class="text-center text-md-left">
              <b-link :href="linkNetwork" target="_blank" class="text-white">
                {{ headerData.username }}
              </b-link>
            <b-icon v-if="headerData.is_verified" icon="check-circle-fill" aria-hidden="true" class="iconCheck" ref="iconCheck"></b-icon>
          </h2>
          <p class="mb-0 text-center text-md-left text-white d-none d-md-block">
            {{ shortText(number_words, headerData.designation ? headerData.designation : '') }}
            <span class="text-info ml-1 cursor-pointer-mt-10" @click="toggleReadMore(headerData.designation)" v-if="headerData.designation && headerData.designation.split(' ').length > 35">
              {{toggle_read_more ? $t('instagram.seeMore') : $t('instagram.seeLess')}} 
            </span>
          </p>
          <p class="text-white mb-1 d-none d-md-block">
             {{$t('instagram.Updatedreport')}}  <span v-if="$i18n.locale === 'es'">{{getDate(headerData.updated_at)[0]}} de {{ $t(`profile.months.${getDate(headerData.updated_at)[1]}`)}} del {{getDate(headerData.updated_at)[2]}}</span>
            <span v-if="$i18n.locale === 'en'">{{getDate(headerData.updated_at)[0]}} {{ $t(`profile.months.${getDate(headerData.updated_at)[1]}`)}} {{getDate(headerData.updated_at)[2]}}</span>
          </p>
        </div>
        <!--/ profile title -->
      </div>
      <div class="bg-white float-right span-geo-profile d-flex align-items-center">
        <div v-if="geo !== null" class="mr-1">
          <feather-icon icon="MapPinIcon" class="text-muted"/>
          <span>{{ geo }}</span>
        </div>
        <div>
          <feather-icon icon="BookOpenIcon" class="text-muted"/>
          <span>{{ languages }}</span>
        </div>

      </div>

    </div>
    <!--/ profile picture -->

    <!-- profile navbar -->
    <div class="profile-header-nav text-center">
      <b-navbar
        toggleable="md"
        type="light"
        class="profile-header-nav"

      >
        <!-- toggle button -->
        <b-navbar-toggle
          class="ml-auto mt-2"
          target="nav-text-collapse"
        >
          <feather-icon
            icon="AlignJustifyIcon"
            size="21"
          />
        </b-navbar-toggle>
        <!--/ toggle button -->

        <!-- collapse -->
        <b-collapse
          id="nav-text-collapse"
          is-nav
          v-model="navbar_action"
        >
          <div 
            class="col-12 container-tabs-header d-flex justify-content-between flex-wrap mt-2 mt-xl-0 align-items-center" 
            :class="{'d-xl-block': !is_in_creator_view}"

          >
            <div :class="`toggle-sm-hidden ${is_in_creator_view ? 'container-dropdowns-actions-creator' : 'container-dropdowns-actions'}  ml-0 ml-xl-1`">
              <dropdown-campaigns 
                :campaigns="campaigns" 
                :disabled="false" 
                :influencers="influencer_to_campaign" 
                :campaigns_influencer="campaigns_influencer"
                @addedCampaign="$emit('addedCampaign')"
                v-if="!is_in_creator_view"
                class="mr-1"
                :padding_custom="'p-09'"
              />
              <dropdown-lists 
                :lists="lists" 
                :disabled="false" 
                :influencers="influencer_to_campaign" 
                :lists_influencer="lists_influencer"
                v-if="!is_in_creator_view"
                class="mr-1"
                :padding_custom="'p-09'"
              />
              <b-dropdown variant="link" toggle-class="p-0" no-caret>
                <template #button-content>
                  <b-button
                    variant="outline-secondary" 
                    class="p-09"
                    id="btnPrintSummary"
                  >
                    <b-icon icon="printer"/>
                      <b-tooltip
                        target="btnPrintSummary"
                        triggers="hover"
                        placement="top"
                      >
                        {{$t('printSummary')}}
                      </b-tooltip>
                  </b-button>
                </template>
                <b-dropdown-item class="force-item-color" :disabled="checkButton" @click="shortPrint('png')">{{$t('profile.printPng')}}</b-dropdown-item>
                <b-dropdown-item class="force-item-color" :disabled="checkButton" @click="shortPrint('pdf')">{{$t('profile.printPdf')}}</b-dropdown-item>
                <b-dropdown-item class="force-item-color" v-if="!is_in_creator_view" @click="shortPrint('web')">{{$t('profile.printWeb')}}</b-dropdown-item>
              </b-dropdown>

              <b-button-group class="btn-email" v-if="email_influencer !== null">
                <b-button variant="outline-secondary" class="p-09">{{ email_influencer }}</b-button>
                <b-button variant="outline-secondary" id="tooltip_copy"  @click="copyAction(email_influencer)"><feather-icon icon="CopyIcon" size="16"/></b-button>
                <b-tooltip target="tooltip_copy">{{$t('ia.chat.copy')}}</b-tooltip>
              </b-button-group>
                
            </div>
            
            <div class="toggle-sm-hidden" id="tabAvatares">
              <div class="d-flex align-items-center justify-content-center justify-content-xl-end flex-wrap flex-md-nowrap">
                <div class="d-flex justify-content-center mr-1 mt-1" :class="{'mb-1': !is_in_creator_view}">
                  <b-badge :class="`${getBadgeNetwork(headerData.main.network)} button-net-profile`" :variant="getBadgeNetwork(headerData.main.network)">
                    <b-img :src="getIconWhite(headerData.main.network)" class="icon-tab-network"/>
                    <span>{{capitalize(headerData.main.network)}}</span>
                  </b-badge>
                </div>
                <b-link class="mr-2 button-see-content" :href="linkNetwork" target="_blank">
                  <span class="white-space">{{$t('profile.see_contents')}}</span>
                </b-link>

                <div class="d-flex mr-1">
                  <b-icon
                    :class="`cursor-pointer-mt-10 ${is_updating_profile ? 'loader-icon-update-profile' : ''}`"
                    font-scale="2"
                    icon="arrow-counterclockwise"
                    variant="primary"
                    @click="is_in_creator_view && !is_brand ? open_modal_unlock_report = true : $emit('updateProfile')"
                    id="btnUpdate"
                  >
                  </b-icon>

                  <b-tooltip
                    target="btnUpdate"
                    triggers="hover"
                    placement="top"
                  >
                    {{$t('update-profile')}} <br>
                    {{$t('profile.lastUpdate')}}: {{headerData.updated_at}}
                  </b-tooltip>
                </div>
                <div class="d-flex mr-1">
                  <b-icon
                  :class="btnClasses"
                  font-scale="2"
                  icon="archive"
                  variant="primary"
                  :disabled="checkButton"
                  @click="downloadPdf"
                  id="btnPrint"
                  >
                  </b-icon>
                  <b-tooltip
                    target="btnPrint"
                    triggers="hover"
                    placement="top"
                  >
                    {{$t('printPage')}}
                  </b-tooltip>
                </div>

                <div class="d-flex mr-1" v-if="main.network === 'instagram' && role !== 'espejo'  && !is_in_creator_view">
                  <b-icon
                  class="cursor-pointer-mt-10"
                  font-scale="2"
                  icon="cart4"
                  variant="primary"
                  @click="showShoppingCart"
                  >
                  </b-icon>
                </div>

                <b-avatar-group size="42" v-if="this.children.length > 0 && !is_in_creator_view">
                  <div class="d-flex">
                    <b-avatar
                    v-for="(child, index) in children"
                    :key="index"
                    @click="clickChild(typeToNetwork(child.type), child.social_id)"
                    :src="child.avatar_url"
                    :id="'tooltip-child-' + index"
                    size="42"
                    badge-variant="light"
                    button
                    class="b-avatar-group"
                    >
                      <b-img :src="children_image[index]" width="42" height="42"/>
                      <template #badge>
                        <b-avatar class="size-avatar-net" variant="light">
                          <b-img :src="getIconWhite(typeToNetwork(child.type))" :class="getIconColor(typeToNetwork(child.type), false)" fluid class="size-img-net"/>
                        </b-avatar>
                      </template>
                      <b-tooltip
                        :target="'tooltip-child-' + index"
                        triggers="hover"
                        placement="top"
                      >
                        {{ getTextTooltip(typeToNetwork(child.type), child.social_id) }}
                      </b-tooltip>
                    </b-avatar>
                  </div>
                </b-avatar-group>

              </div>
            </div>
            <div class="d-flex flex-column col-12 d-none d-sm-flex d-md-none text-toggle-end">
              <b-dropdown class="link-menu" variant="link" toggle-class="p-0" no-caret>
                <template #button-content>
                    <b-link class="link-menu space-text"> 
                      <b-icon icon="printer" class="mr-1"/>{{$t('printSummary')}}</b-link><hr class="hr-style"/>
                </template>
                <b-dropdown-item class="force-item-color" :disabled="checkButton" @click="shortPrint('png')">{{$t('profile.printPng')}}</b-dropdown-item>
                <b-dropdown-item class="force-item-color" :disabled="checkButton" @click="shortPrint('pdf')">{{$t('profile.printPdf')}}</b-dropdown-item>
                <b-dropdown-item class="force-item-color" v-if="!is_in_creator_view" @click="shortPrint('web')">{{$t('profile.printWeb')}}</b-dropdown-item>
              </b-dropdown>
              <b-link class="link-menu space-text" :href="linkNetwork" target="_blank">
                <b-icon icon="eye" class="mr-1"/>{{$t('profile.see_contents')}}<hr class="hr-style"/></b-link>

                <b-link class="link-menu space-text" @click="copyAction(email_influencer)" v-if="email_influencer !== null">
                  <feather-icon v-if="email_influencer !== null" icon="CopyIcon" class="mr-1"/>{{ email_influencer }}<hr class="hr-style"/></b-link>
                <b-link id="update" class="link-menu space-text" @click="is_in_creator_view ? open_modal_unlock_report = true : $emit('updateProfile')">
                  <b-icon icon="arrow-counterclockwise" class="mr-1"/>{{$t('profile.lastUpdate')}}: {{headerData.updated_at}}<hr class="hr-style"/></b-link>
                <b-tooltip target="update">{{$t('update-profile')}}</b-tooltip>
                <b-link 
                  class="link-menu space-text"
                  :disabled="checkButton"
                  @click="downloadPdf">
                  <b-icon icon="archive" class="mr-1"/>{{$t('printPage')}} 
                  <hr class="hr-style"/>
                </b-link>
                <b-link class="col-12 link-menu space-text" @click="showShoppingCart" v-if="(main.network === 'instagram' && role !== 'espejo') && !is_in_creator_view">
                  <b-icon icon="cart4" class="mr-1"/>{{$t('costContent')}} </b-link>
              </div>
          </div>

        </b-collapse>
        <!--/ collapse -->
      </b-navbar>
    </div>
    <shopping-cart :avatar="imageCampaign" :info="info" :prices="prices" :campaigns="campaigns" :modalShow="modalShow" :headerData="headerData" :key="changeCart"/>
    <b-modal v-model="open_modal_unlock_report" centered hide-footer hide-header size="lg">
      <div class="p-1 d-flex justify-content-around">
        <feather-icon
          icon="XIcon"
          size="24"
          class="icon-close-modal-unlock"
          @click="open_modal_unlock_report = false"
        />
        <b-button class="blue-button mb-1 mr-1" variant="blue-button" v-if="is_plan_free || (Object.keys(permission_metrics).length > 0 && permission_metrics.reports.available_credits === 0) && !is_brand">{{$t('costContent')}}</b-button>
        <b-button class="blue-button mb-1 mr-1" variant="blue-button" v-else @click="updateOrBuyMetrics(false)">
          {{$t('updateReport')}}
          <b-img :src="require(`@/assets/images/icons/moneda.svg`)" class="icon-coin"></b-img>
        </b-button>
  
        <b-button variant="outline-secondary" class="mb-1" @click="updateOrBuyMetrics(true)">{{$t('buyUpdate')}}</b-button>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BButtonGroup,
  BCard, 
  BImg, 
  BNavbar, 
  BNavbarToggle, 
  BCollapse,
  BBadge, 
  BAvatar, 
  BTooltip, 
  BIcon, 
  BAvatarGroup, 
  BImgLazy, 
  BLink, 
  BButton,
  BDropdown,
  BDropdownItem,
  BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import service from '@/services/profile'
import shoppingCart from '@/views/pages/profile/shoppingCart'
import campaign_service from '@/services/dashboard';
import { required } from "@validations";
import dropdownCampaigns from '@/views/campaings/dropdownCampaigns.vue';
import dropdownLists from '@/views/campaings/dropdownLists.vue';
import service_list from "@/services/lists";
import { print, getLanguage, getCountry, getRandom } from '@/libs/utils/others';
import { getUserData } from '@/libs/utils/user'
import { getIconWhite, getIconColor, getBadgeNetwork } from '@/libs/utils/icons'
import { capitalize, shortText, urlImagetoBase64 } from '@/libs/utils/formats';
import { isUrl, getAssetUrl } from '@/libs/utils/urls'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  components: {
    BButtonGroup,
    BAvatar,
    BCard,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
    BBadge,
    BTooltip,
    BIcon,
    BAvatarGroup,
    BImgLazy,
    shoppingCart,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BModal,
    dropdownCampaigns,
    dropdownLists
  },
  directives: {
    Ripple,
  },
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
    info: {
      type: Object,
      default: () => {},
    },
    namePdf: {
      type: String,
      default: `profile.pdf`
    },
    section: {
      type: String,
      default: 'general'
    },
    show_general: {
      type: Boolean,
      default: true
    },
    influencer_to_campaign: {
      type: Array,
      default: () => []
    },
    campaigns_influencer: {
      type: Array,
      default: () => []
    },
    lists_influencer: {
      type: Array,
      default: () => []
    },
    is_updating_profile: {
      type: Boolean,
      default: false,
    },
    metrics: {
      type: Object,
      default: () => {
        return {}
      }
    },
    permission_metrics: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      getCountry,
      getLanguage,
      isUrl,
      getAssetUrl,
      urlImagetoBase64,
      shortText,
      network_type_map: {
        1: 'instagram',
        2: 'youtube',
        3: 'tiktok',
        4: 'twitch',
        5: 'twitter'
      },
      getBadgeNetwork,
      capitalize,
      getIconWhite,
      getIconColor,
      required,
      toggle_read_more: true,
      number_words: 35,
      active_tabs: 0,
      children: [],
      children_image: [],
      avatar: this.headerData.avatar,
      checkButton: false,
      btnClasses: 'cursor-pointer-mt-10',
      variants: [
        'bg-primary',
        'bg-secondary',
        'bg-danger',
      ],
      background_image: '',
      image_header: '',
      prices: [],
      campaigns: [],
      changeCart: false,
      modalShow: false,
      imageCampaign: this.headerData.avatar,
      role: 'brand',
      linkNetwork: '',
      languages: '',
      geo: '',
      email_influencer: null,
      add_class: false,
      navbar_action: null,
      selected_campaign: null,
      lists: [],
      open_modal_unlock_report: false,
    }
  },
  beforeCreate() {
    service.getChildren(this.$route.params.network, this.$route.params.alias)
      .then(response => {
        if (response.status === 200) {
          const children = Object.values(response.data.data[0].social_networks).filter(child => {
            const username = child.type === 2 ? child.social_id.replace(`${child.username.toLowerCase()}-`, '') : child.social_id;
            const self = this;
            if (username === this.$route.params.alias && this.typeToNetwork(child.type) === this.$route.params.network) {
              this.imageCampaign = child.avatar_url;
              urlImagetoBase64(child.avatar_url, function (result) {
                self.avatar = result
              });
            } else {
              urlImagetoBase64(child.avatar_url, function (result) {
                self.children_image.push(result)
              });
              return child
            }
          });
          this.children = children
        }
      })
      .catch(() => {
      })

    service.getCover()
      .then(response => {
        if (response.cover_url) {
          if (isUrl(response.cover_url)) {
            this.image_header = response.cover_url
          } else {
            this.image_header = getAssetUrl(response.cover_url)
          }

          const self = this;

          urlImagetoBase64(this.image_header, function (result) {
            self.background_image = {
              "background-image": `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%), url('${result}')`,
            }
          });
        } else {
          this.background_image = {
            "background-image": `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%), url()`,
          }
        }
      })
  },
  computed: {
    main() {
      return {
        username: this.headerData.username,
        network: this.headerData.main.network
      }
    },
    is_in_creator_view() {
      return Object.keys(this.metrics).length > 0
    },
    is_plan_free() {
      return getUserData().current_plan.name.toLowerCase() === 'free'
    },
    is_brand() {
      return getUserData() && getUserData().main_group.name !== 'creator'
    }
  },
  created() {
    this.getCampaigns();
    this.getLists();
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.role = userData.groups[0].name
    this.getLinkNetwork();
    setTimeout(() => {
      this.getGeo();
      this.getLanguages();
      this.getEmailInfluencer();
    }, 200)
    if (isUrl(this.headerData.avatar)) {
      const self = this;
      urlImagetoBase64(this.headerData.avatar, function (result) {
        self.avatar = result
      });
    }
  },
  mounted() {
    this.changeIconCircle();
  },
  methods: {
    copyAction(link) {
      this.$copyText(link).then(() => {
        loadToastificationContent().then((module) => {
          this.$toast({
            component: module.default,
            position: "top-right",
            props: {
              title: this.$t('campaigns.copied'),
              icon: "CopyIcon",
              text: this.$t('campaigns.clipboardEmail'),
              variant: 'success'
            },  
          });
        })
      });
    },
    typeToNetwork(type) {
      return this.network_type_map[type];
    },
    updateOrBuyMetrics(is_buying) {
      if (is_buying) this.$emit('update_metrics', true);
      else this.$emit('update_metrics', false); this.permission_metrics.already_viewed = true
      this.open_modal_unlock_report = false;
    },
    isSuperUser() {
      return getUserData().is_superuser
    },
    getEmailInfluencer() {
      if (this.headerData.main.network === 'instagram') {
        this.email_influencer = (this.info.main_child && this.info.main_child.detail.contact_details && this.info.main_child.detail.contact_details.emails && this.info.main_child.detail.contact_details.emails.length !== 0) ? this.info.main_child.detail.contact_details.emails[0] : null;
      } else {
        this.email_influencer = (this.info.report && this.info.report.features && this.info.report.features.blogger_emails && this.info.report.features.blogger_emails.data && this.info.report.features.blogger_emails.data.length !== 0) ? this.info.report.features.blogger_emails.data[0] : null;
      }
    },
    getLanguages() {
      if (this.headerData.main.network === 'instagram') {
        this.languages = this.info.main_child ? this.info.main_child.detail.blogger_languages.map(code => getLanguage(code)).reduce((str, name) => `${str},${name}`) : [];
      } else {
        this.languages = (this.info.report && this.info.report.features && this.info.report.features.blogger_languages && this.info.report.features.blogger_languages.data !== null) ? this.info.report.features.blogger_languages.data.map(code => getLanguage(code)).reduce((str, name) => `${str},${name}`) : [];
      }
    },
    getGeo() {
      if (this.headerData.main.network === 'instagram')
        this.geo = (this.info.main_child && this.info.main_child.detail.blogger_geo.length !== 0) ? getCountry(this.info.main_child.detail.blogger_geo.country) : null
      else
        this.geo = (this.info.report && this.info.report.features && this.info.report.features.blogger_geo && this.info.report.features.blogger_geo.data !== null) ? getCountry(this.info.report.features.blogger_geo.data.country) : null
    },
    toggleReadMore(text) {
      (this.toggle_read_more) ? this.number_words = text.split(' ').length : this.number_words = 35;
      this.toggle_read_more = !this.toggle_read_more;
    },
    getLinkNetwork() {
      const net = this.headerData.main.network;
      if (net === 'instagram') {
        this.linkNetwork = `https://www.instagram.com/${this.headerData.username}`
      } else if (net === 'tiktok') {
        this.linkNetwork = `https://www.tiktok.com/@${this.headerData.username}`
      } else if (net === 'youtube') {
        this.linkNetwork = `https://www.youtube.com/channel/${this.headerData.username_channel_id}`
      } else if (net === 'twitch') {
        this.linkNetwork = `https://www.twitch.tv/${this.headerData.username}`
      } else if (net === 'twitter') {
        this.linkNetwork = `https://twitter.com/${this.headerData.username}`
      } 
    },
    getVariant() {
      return this.variants[getRandom(0, 2)]
    },
    getTextTooltip (network, social_id) {
      if (network === 'youtube') {
        const username_channel_id = social_id.split('-');
        return username_channel_id[0] ? `${username_channel_id[0]}` : `${social_id}`
      } else return `${social_id}`
    },
    getChannelIdYoutube(social_id) {
      const username_channel_id = social_id.split('-');

      return username_channel_id[1] ? username_channel_id[1] : social_id
    },
    clickChild(network, social_id) {
      const username = network === 'youtube' ? this.getChannelIdYoutube(social_id) : social_id
      window.open(`/perfil/${network}/${username}`);
    },
    shortPrint(format) {
      if (format === 'web') {
        window.open(`/resumen/perfil/${this.$route.params.network}/${this.$route.params.alias}`);
      } else {
        this.$emit('printShortChild', this.avatar, format)
      }
    },
    downloadPdf() {
      this.checkButton = true;
      this.btnClasses = '';

      this.$emit('childDisableEmbed', {
        section: this.section
      })

      // this.$refs.toPdf
      const self = this;
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setTimeout(function() {
        print('p', `${self.namePdf}`, document.body, function () {
          self.checkButton = false;
          self.btnClasses = 'cursor-pointer-mt-10';
          self.$emit('childEnableEmbed', {
            section: self.section
          })
        })
      }, 500);
    },
    showShoppingCart() {
      this.prices = this.headerData.estimations;
      this.modalShow = true;
      this.changeCart = !this.changeCart;
    },
    getCampaigns() {
      campaign_service.getCampaign().then((res) => {
        res.response.results.filter((item) => {
          const obj = {value:item.uuid, text: item.name}
          this.campaigns.push(obj)
        });
      });
    },
    orderLists(lists) {
      lists.sort(function(a, b) {
        return a.order - b.order;
      });
      return lists
    },
    getLists() {
      service_list.getLists().then((response) => {
        const lists_ordered = this.orderLists(response.results)
        lists_ordered.filter((item) => {
          const obj = {value:item.uuid, text: item.title}
          this.lists.push(obj)
        });
      });
    },
    getDate(date) {
      return date.split('/')
    },
    changeIconCircle() {
      if (this.$refs.iconCheck) this.$refs.iconCheck.firstChild.firstChild.attributes[1].value = 'M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z'
    }
  },
  watch: {
    navbar_action(val) {
      this.add_class = val;
    }
  }
}
</script>

<style type="text/css" lang="scss">
.p-09 {
  padding: 11px 10px;
}
.btn-email {
  margin: 10px
}
.size-img-net {
  height: 20px !important;
  width: 20px
}
.size-avatar-net {
  height: 18px !important;
  width: 30px  !important;
}
.icon-close-modal-unlock {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgb(39, 31, 31);
  cursor: pointer;
  &:hover {
    background-color: rgba(55, 57, 57, 0.17);
    border-radius: 50%;
    padding: 2px;
  }
}
  .container-dropdowns-actions {
    position: absolute;
    padding-left: 10em;
    @media(max-width: 1380px) {
      padding-left: 0;
      position: static;
    }
  }
  .container-dropdowns-actions-creator {
    text-align: left;
  }

  .text-toggle-end{
    text-align: end;
    align-items: flex-end;
    
  }
  .link-menu{
    color: #000000;
  }
  .link-menu:hover{
    color: #7367F0 !important;
  }
  .hr-style{
    margin-bottom:0px;
    margin-top: 6px;
  }
  .space-text{
    font-weight: normal;
    padding: 0px;
  } 
  .tab-right {
    position: absolute;
    right: 1px;
    top: 10px;
  }

  .b-avatar-group{ 
    min-width: 42px  !important  ;
    margin-right:5px ;
  }
  .b-avatar .b-avatar-badge {
    z-index: initial !important;
    
  }
  .profile-header > .card-header {
    padding: 0px;
  }
  .image-gradient {
    width: 100%;
    height: 250px;
    background-size: cover;
  }
  .cursor-pointer-mt-10 {
    cursor: pointer;
  }
  .icon-tab-network {
    margin-right: 0.6em !important;
    width: 1.4em;
  }
  .button-see-content {
    border: 1px solid #0096fe;
    padding: 0.5em;
    border-radius: 5px;
    transition: all 250ms;
  }
  .button-see-content:hover {
    background:  #0818CD;
    border: 1px solid #0d1892;
  }
  .button-see-content:hover span {
    color: white;
  }
  .button-see-content span {
    color: #0096fe;
  }
  .button-net-profile {
    width: 150px;
    border: none !important;
    box-shadow: rgba(237, 74, 96, 0.082) 0px 7px 29px 0px !important;
    padding: 10px;
    color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mt-50 {
    margin: 12em 0 0 0 !important;
  }
  .iconCheck {
    color: rgb(66, 165, 245);
  }
  .b-avatar-profile-header {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .span-geo-profile {
    float: right;
    margin: -3.7em 0.7em;
    padding: 7px;
    border-radius: 8px;
    font-size: 13px;
  }
  .span-geo-profile span {
    margin: 0 0 0 5px;
  }
  .mail-icon-mr {
    margin: 0 5px;
  }
  .email-influencer-container {
    color: #3837AF;
    margin: 10px 0 0 0;
  }
  .white-space {
    white-space: nowrap;
  }
  .network-icon{
      margin-left: 90px;
      top: 100px;
      position: absolute;
    } 
  @media(min-width: 991px) and (max-width: 1370px) {
    .profile-img {
      margin-bottom: 20px !important;
    }
  }
  @media(max-width: 1760px) {
    .container-summary-add {
      display: flex;
      justify-content: end;
    }
  }
  @media(min-width:768px) {
    .text-white {
      color: white !important;
    }
    .network-icon{
      display: none;
    }
  }
  @media(max-width:767px) {
    #nav-text-collapse {
      background-color: white;
      position: absolute;
      top: 5em;
      bottom: auto;
      width: 100%;
      left: 0;
      z-index: 10;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
      padding: 1em;
      border-radius: 0.5em;
    }
    .toggle-sm-hidden{
      display: none !important;
    }
    .profile-img-container {
      top: -70px !important;
      left: 0 !important;
    }
    .profile-img {
      margin-bottom: 20px;
    }
    .image-gradient {
      height: 250px;
    }
    .profile-header-height {
      height: 380px;
    }
    .profile-header a, .profile-header a{
      color: #000000 !important;
    }
  }
  .force-item-color a {
    color: #0f131a !important;
  }
  @media(max-width:1183px) {
  .span-geo-profile {
    position: absolute;
    right: 0.5em;
    top: -15em;
   } 
  }
  @media(max-width:576px) {
    .network-icon{
      margin-left: 70px;
      top: 80px;
      position: absolute;
    }
  }
</style>
<style scoped>
.icon-coin {
  width: 20px;
  height: 20px;
}
.loader-icon-update-profile {
  animation: animation-rotation 1s infinite linear;
}
@keyframes animation-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}
</style>